import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { createSkyKickAngularProvider, SkyKickProvidersBuilder } from '@platform/dependency-injection';
import { CachingModule } from '@skykick/caching';
import { AppId, BannerModule, HeaderModule, LayoutModule, NavigationModule, UtilityModule } from '@skykick/core';
import { IPartnerCenterConnectionService, PartnerCenterActivationModule } from '@skykick/partner-center';
import { AbstractUserProvider, AuthModule } from '@skykick/platform-identity-auth-auth0-angular';

import { localPlatformServicesStubProvider } from '../environments/local/platform-services.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InitializationService } from './initialization.service';
import { PartnerCenterConnectionService } from './pci/infrastructure/partner-center-connection.service';
import { windowProvider, WindowToken } from './window';

export function getRoutingDomain() {
    if (window.location.hostname.includes('.com')) {
        return `https://routing.skykick.com`;
    }

    return `https://nonprod-routing.skykick.dev`;
}

export function LanguageLoader(translate: TranslateService, cookieService: CookieService): () => Promise<void> {
    const promise = new Promise<void>(resolve => {
        const cookieLang = cookieService.get('skLanguageCode');
        const browserLang = cookieLang || translate.getBrowserLang();
        const lang = browserLang.match(/en|ja|sq|de/) ? browserLang : 'en';
        translate.use(lang);
        resolve();
    });
    return async () => promise;
}

export function TranslationLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const providers: Provider[] = [
    ...new SkyKickProvidersBuilder()
        .add(createSkyKickAngularProvider(environment.userProvider, AbstractUserProvider, { alreadyExists: environment.production }))
        .build(),
    { provide: WindowToken, useFactory: windowProvider },
    CookieService,
    {
        provide: APP_INITIALIZER,
        useFactory: (initializationService: InitializationService) => async () => await initializationService.run(),
        deps: [
            InitializationService
        ],
        multi: true
    },
    {
        provide: APP_INITIALIZER,
        useFactory: LanguageLoader,
        multi: true,
        deps: [TranslateService, CookieService]
    }
];

export function PartnerCenterConnectionServiceFactory(http: HttpClient) {
    return new PartnerCenterConnectionService(http);
}

if (!environment.production) {
    providers.push(localPlatformServicesStubProvider);
}

@NgModule({
    declarations: [
        AppComponent
    ],
    exports: [
        RouterModule
    ],
    bootstrap: [AppComponent],
    imports: [
        BannerModule,
        AppRoutingModule,
        AuthModule.forRoot({
            LicenseAssignmentServiceBaseUrl: `${getRoutingDomain()}/pipe/${AppId.PlatformApis}/licenseassignment/api/v1`,
            SewrSystemDomain: getRoutingDomain(),
        }),
        NavigationModule,
        HeaderModule,
        LayoutModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        UtilityModule.forRoot({
            sewrSystemDomain: getRoutingDomain()
        }),
        TranslateModule.forRoot({
            loader: { provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient] },
            isolate: true
        }),
        PartnerCenterActivationModule.forRoot({
            provide: IPartnerCenterConnectionService,
            useFactory: PartnerCenterConnectionServiceFactory,
            deps: [HttpClient]
        }),
        CachingModule
    ],
    providers: [
        ...providers,
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
